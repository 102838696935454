<template>
  <div style="position: absolute;">
    <client-only>
      <PremiumModal />
    </client-only>
  </div>
</template>

<script>
import userInfo from '~/mixins/userInfo'
import modalNames from '~/modals/modalNames'

export default {
  name: 'PopUpPremium',
  components: {
    PremiumModal: () => import('@/components/modals/PremiumModal')
  },
  mixins: [userInfo],
  props: {
    blocked: {
      default: false
    }
  },
  data () {
    return {
      disallowedPages: [
        /abo-premium-.*/,
        /confirmation-de-.*/,
        /-premium-subscribe/,
        /paiement/,
        /telechargement-kbis/,
        /obtenir-kbis/,
        /numero-tva-intracommunautaire/,
        /diagnostic-financier-nota-pme/,
        /avis-situation-sirene-insee/,
        /premium/
      ],
      openTimeoutId: null
    }
  },
  computed: {
    openedModal () {
      return this.$modals?.openedModals[0]
    }
  },
  watch: {
    openedModal () {
      if (this.openedModal && this.openedModal !== modalNames.PremiumModal) {
        this.destroyPopUpTimer()
      } else {
        this.startPopUpTimer()
      }
    },
    blocked (v) {
      if (!v) {
        this.startPopUpTimer()
      }
    },
    "$route.path" (newValue) {
      if (!this.isAllowedPage() && this.openTimeoutId != null) this.destroyPopUpTimer()
      else if (this.openTimeoutId == null) this.startPopUpTimer()
    }
  },
  mounted () {
    this.startPopUpTimer()
  },
  methods: {
    isAllowedPage () {
      for (const i in this.disallowedPages) {
        const x = this.disallowedPages[i]
        if (x.test(this.$route.path)) return false
      }
      return true
    },
    startPopUpTimer () {
      if (this.timePassed() && this.isAllowedPage() && !this.blocked) {
        this.openTimeoutId = setTimeout(() => {
          this.$modals.showModal(this.$modals.names.PremiumModal)
          localStorage.lastPopupDate = Date.now()
        }, 60000)
      }
    },
    timePassed () {
      if (this.hasSubscription) return false
      return !localStorage.lastPopupDate || ((Date.now() - localStorage.lastPopupDate) > 12 * 60 * 60 * 1000)
    },
    destroyPopUpTimer () {
      clearTimeout(this.openTimeoutId)
      this.openTimeoutId = null
    }
  }
}
</script>
